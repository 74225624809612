import { useQuery } from '@apollo/client/react/hooks';
import loadable from '@loadable/component';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { CategoryPlpContentQuery, CategoryPlpContentQueryVariables } from '../../../__generated__/graphql-client-types';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { StyledButton } from '../../../components/buttons';
import { Carousel } from '../../../components/carousel/carousel.component';
import { Badge } from '../../../components/common-components/badge/badge.component';
import { PageLoading } from '../../../components/common-components/loading/loading.component';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { CornerRibbon } from '../../../components/corner-ribbon/corner-ribbon.component';
import { FiveHundred } from '../../../components/error-components/500/500.component';
import { GroupContent } from '../../../components/group-content/group-content.component';
import { SelectBox } from '../../../components/inputs/select-box/select-box.component';
import { PromoBanner } from '../../../components/promo-banner/promo-banner.component';
import { PromotedFilterListV1 } from '../../../components/promoted-filter-list-v1/promoted-filter-list-v1.component';
import { PromotedFilterListV2 } from '../../../components/promoted-filter-list-v2/promoted-filter-list-v2.component';
import { FacetDrawer } from '../../../components/search-components/facet-drawer/facet-drawer.component';
import { FacetFilterList } from '../../../components/search-components/facet-filter-list/facet-filter-list.component';
import { FacetFilterListV2 } from '../../../components/search-components/facet-filter-list-v2/facet-filter-list-v2.component';
import { FacetList } from '../../../components/search-components/facet-list/facet-list.component';
import { SearchHeader } from '../../../components/search-components/search-header/search-header.component';
import { PlpNavVariations, SearchHeaderV2 } from '../../../components/search-components/search-header-v2/search-header-v2.component';
import { TuneIcon } from '../../../components/svg/icons.component';
import { CATEGORY_PLP_PAGE_NAME, FEATURE_FLAGS } from '../../../constants/general';
import { CATEGORY_SORT_OPTIONS, SEARCH_SORT_OPTIONS_MOBILE } from '../../../constants/search';
import { TrackedEvent, TrackedEventCase } from '../../../helpers/analytics/event-types';
import { buildGTMSearch, buildGTMPromoBannerDisplay } from '../../../helpers/analytics/gtm/event-builders';
import { isBrandCategory, mapPromoBannersByPriority } from '../../../helpers/category-helper/category.helper';
import { isChromatic } from '../../../helpers/general-helper/general-helper';
import { generatePageViewSearchDataLayer, getTrackingSearchTermFromDropType } from '../../../helpers/search-helper/search-analytics.helper';
import { isRangeFacetGroup } from '../../../helpers/search-helper/search-helper';
import { convertToProductSortEnum } from '../../../helpers/search-helper/search-product-helper';
import { useTrackEvent, useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useTrackImpressions } from '../../../hooks/analytics/impressions.hooks';
import { useSiteViewPreference } from '../../../hooks/apollo/employee/employee.hooks';
import { useGetSearchSource, useProductViewPreference, useSearchResults } from '../../../hooks/apollo/search/search.hooks';
import { useSetRumViewName } from '../../../hooks/datadog/rum.hooks';
import { DyRecommendationContextType, useDyRecommendationContext } from '../../../hooks/dynamic-yield/dynamic-yield-client.hooks';
import { useFeatures } from '../../../hooks/features/features.hooks';
import { CATEGORY_PLP_CONTENT } from '../../../queries/content/content.queries';
import { CategoryContentResult, CategoryDropPageContent } from '../../../types/content.types';
import { Category, SearchRequestData } from '../../../types/search.types';
import { isCategoryCard } from '../../../utils/typeguards/content.typeguards';
import { CategoryBrowseList } from '../category-browse/components/category-browse-list/category-browse-list.component';
import { CategoryContent } from '../category-browse/components/category-content/category-content.component';
import { CategoryImageTile, CategoryTextTile } from '../category-browse/components/category-tile/category-tile.component';

const LoadableSearchResults = loadable(
	() => import(/* webpackChunkName: "search-results" */ '../../../components/search-components/search-results/search-results.component'),
	{
		resolveComponent: ({ SearchResults }) => SearchResults
	}
);

const LoadableSearchFooter = loadable(
	() => import(/* webpackChunkName: "search-footer" */ '../../../components/search-components/search-footer/search-footer.component'),
	{
		resolveComponent: ({ SearchFooter }) => SearchFooter
	}
);

const LoadableNoResults = loadable(
	() =>
		import(
			/* webpackChunkName: "no-search-results" */ '../../../components/search-components/no-search-results/no-search-results.component'
		),
	{
		resolveComponent: ({ NoResults }) => NoResults
	}
);

const LoadableNoFilteredSearchResults = loadable(
	() =>
		import(
			/* webpackChunkName: "no-filtered-search-results" */ '../../../components/search-components/no-filtered-search-results/no-filtered-search-results.component'
		),
	{
		resolveComponent: ({ NoFilteredSearchResults }) => NoFilteredSearchResults
	}
);

const LoadableSolrAlertNotification = loadable(
	() =>
		import(
			/* webpackChunkName: "solr-alert-notification" */ '../../../components/search-components/solr-alert-notification/solr-alert-notification.component'
		),
	{
		resolveComponent: ({ SolrAlertNotification }) => SolrAlertNotification
	}
);

const isCategoryDropContentResult = (result: CategoryContentResult | undefined): result is CategoryDropPageContent => {
	return result?.__typename === 'CategoryDropPageContent';
};

export type CategoryProductDropProps = {
	category: Category;
};

export const CategoryProductDrop: FunctionComponent<CategoryProductDropProps> = ({ category }) => {
	const { title, name, metaData, subCategories } = category;
	const { pathname, search } = useLocation();
	const {
		results,
		page,
		pageSize,
		sortBy,
		previousResults,
		loading,
		categoryId,
		request,
		error,
		isNonstock,
		setSortBy,
		isNewLookAndFeel
	} = useSearchResults();
	const { baseCategory, businessCategory } = results || {};
	const categoryProductResults = loading ? previousResults : results;
	const [isFacetDrawerOpen, setFacetDrawerOpen] = useState<boolean>(false);
	const showFacetDrawer = () => setFacetDrawerOpen(true);
	const hideFacetDrawer = () => setFacetDrawerOpen(false);
	const { productViewPreference } = useProductViewPreference();
	const { employee, showAsEmployee } = useSiteViewPreference();
	const trackEvent = useTrackEvent();
	const [plpNavVariationsFlag, fusionSearchEnabled, hidePageSize] = useFeatures<[PlpNavVariations, boolean, boolean]>([
		FEATURE_FLAGS.PLP_NAV,
		FEATURE_FLAGS.FUSION_CATEGORY_SEARCH,
		FEATURE_FLAGS.HIDE_CATEGORY_PAGESIZE
	]);
	const isPlpVariationControl = plpNavVariationsFlag === PlpNavVariations.control;

	// #region Construct Data
	const { data: contentData } = useQuery<CategoryPlpContentQuery, CategoryPlpContentQueryVariables>(CATEGORY_PLP_CONTENT, {
		variables: {
			categoryId
		}
	});
	const {
		topPrimary: topPromo,
		topSecondary: topSecondaryPromo,
		bottomSecondary: bottomSecondaryPromo
	} = mapPromoBannersByPriority(contentData?.sharedCategoryPromos || []);
	const categoryContent = isCategoryDropContentResult(contentData?.categoryContent) ? contentData?.categoryContent : undefined;
	const filteredGroupContent = ((fusionSearchEnabled && categoryContent?.groupContent) || []).filter(Boolean);
	// #endregion

	useDyRecommendationContext({
		type: DyRecommendationContextType.CATEGORY_PAGE,
		data: (category.breadcrumbs || []).map((breadcrumb) => breadcrumb.name)
	});
	const categoryType = isBrandCategory(category) ? 'brand' : 'category';

	const pageName = CATEGORY_PLP_PAGE_NAME;
	const dropType = 'category';
	const searchSource = useGetSearchSource();
	const searchDataLayer = generatePageViewSearchDataLayer(
		pageName,
		dropType,
		results,
		page,
		pageSize,
		sortBy,
		undefined,
		searchSource,
		productViewPreference,
		isNonstock
	);

	const categoryContentOverride = category.categoryContentOverride;
	const categoryContentTrackingData = {
		brand: !categoryContentOverride.brand ? 'None' : categoryContentOverride.brand,
		businessCategory: !categoryContentOverride.businessCategory ? businessCategory ?? 'None' : categoryContentOverride.businessCategory,
		baseCategory: !categoryContentOverride.baseCategory ? baseCategory ?? 'None' : categoryContentOverride.baseCategory
	};

	useTrackPageView({ pageName, ...categoryContentTrackingData }, searchDataLayer, loading);

	useTrackImpressions(
		TrackedEvent.CATEGORY_PLP_RESULTS_VIEW,
		getTrackingSearchTermFromDropType(dropType),
		request,
		results,
		productViewPreference,
		isNonstock
	);

	// Search tracking.
	const previousRequestRef = useRef<SearchRequestData | null>(null);
	useEffect(() => {
		if (!loading && !error && request !== previousRequestRef.current) {
			const isNewSearch = request.categoryId !== previousRequestRef.current?.categoryId;
			trackEvent(
				buildGTMSearch(
					TrackedEvent.SEARCH,
					TrackedEventCase.CATEGORY_SEARCH_RESULTS,
					request,
					results,
					productViewPreference,
					employee.isAuthenticated,
					isNonstock,
					isNewSearch
				)
			);

			previousRequestRef.current = request;
		}
	}, [request, loading, error, isNonstock, productViewPreference, results, trackEvent, employee]);

	const { ref: categoryContentRef, inView: categoryContentInView } = useInView({ triggerOnce: true });
	const { ref: footerRef, inView: footerInView } = useInView({ triggerOnce: true });

	const metaDescription =
		metaData.description ||
		`Save on ${name}s from Build.com. Low Prices + Fast & Free Shipping on Most Orders. Find reviews, expert advice, manuals, specs & more.`;

	// if no banner exists, then fire off tracking with an empty priority to indicate no banners are displayed
	useEffect(() => {
		if (contentData?.sharedCategoryPromos && !topPromo && !topSecondaryPromo && !bottomSecondaryPromo)
			trackEvent(buildGTMPromoBannerDisplay());
	}, [contentData?.sharedCategoryPromos]);

	useSetRumViewName('Category Product Drop');

	if (error) {
		return <FiveHundred />;
	}

	const { products = [], breadcrumbs = [], selectedFacetGroups = [], searchEngine, count = 0 } = categoryProductResults ?? {};

	const selectedFacetCounts =
		results?.selectedFacetGroups?.reduce((facetCount, group) => {
			const isRangeGroup = isRangeFacetGroup(group);
			return facetCount + (isRangeGroup ? 1 : group.facets?.length ?? 0);
		}, 0) ?? 0;

	const shouldShowAppliedFilterBadge = selectedFacetCounts > 0;
	const sortChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSortBy(convertToProductSortEnum(event.target.value));
	};

	return (
		<>
			{showAsEmployee && <LoadableSolrAlertNotification searchEngine={searchEngine ?? ''} />}
			<PageContainer canonicalURL={`${pathname}${search}`} metaDescription={metaDescription} pageTitle={metaData.title || name}>
				<>
					{breadcrumbs.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
					{categoryProductResults ? (
						<>
							<div className="mt3">
								{isPlpVariationControl ? (
									<SearchHeader title={title} sortOptions={CATEGORY_SORT_OPTIONS} onShowDrawer={showFacetDrawer} />
								) : (
									<SearchHeaderV2 searchHeaderV2FlagStatus={plpNavVariationsFlag} title={title} />
								)}
								<div className="dn db-l mt2">
									<FacetFilterList />
								</div>
							</div>
							{plpNavVariationsFlag === PlpNavVariations.variation1 && <PromotedFilterListV1 groups={filteredGroupContent} />}
							{/** mobile display */}
							{!isPlpVariationControl && (
								<span id="search-header" className="dn-l flex-ns flex-wrap flex-nowrap-ns justify-between-ns mt3">
									<div className="dn db-l flex flex-wrap flex-nowrap-ns w-100 w-auto-ns mt2 pa1">
										<div className="flex justify-center w-100 mb1">
											<span className="db-m mr2">
												<StyledButton onClick={showFacetDrawer} buttonStyle="SECONDARY" size="DEFAULT">
													<div className="flex items-center fw4 f5" style={{ gap: 7 }}>
														<TuneIcon />
														{`Filter`}
														{shouldShowAppliedFilterBadge ? (
															<Badge count={selectedFacetCounts} noMargin />
														) : null}
													</div>
												</StyledButton>
											</span>
											<span className="relative mr2-ns w-100 w-auto-ns flex-grow-1 flex-grow-0-ns">
												<span
													className="absolute bg-theme-white f7 ph1"
													style={{ marginTop: '-0.4rem', marginLeft: '0.9rem' }}>
													Sort
												</span>
												<SelectBox
													name="sortSelect"
													value={sortBy}
													options={SEARCH_SORT_OPTIONS_MOBILE}
													automationHook="product-sort-by"
													ariaLabel="sort"
													onChange={sortChanged}
													data-testid="sort-select"
													borderStyle="dark"
													className="mb0 w-100 bg-theme-white h-100"
												/>
											</span>
										</div>
									</div>
								</span>
							)}
							{plpNavVariationsFlag === PlpNavVariations.variation2 && <PromotedFilterListV2 groups={filteredGroupContent} />}
							<div className="flex mt3 mt4-l" data-automation="category-product-list-page">
								<div className="w-25-l dn db-l">
									{topSecondaryPromo && <PromoBanner {...topSecondaryPromo} />}
									<FacetList />
									{subCategories && (
										<CategoryBrowseList
											className={isNewLookAndFeel ? 'mt3' : 'items-center justify-start flex-wrap'}
											title={`Shop ${title.replace(/^\s*Shop\s+(All\s)?/i, '').trim()}`}
											titleClass={
												isNewLookAndFeel
													? 'ph2 pv3 f4 fw6 bb b--theme-grey-light'
													: 'pl5-ns pa2 bg-theme-grey-lighter f5 fw4 fw3-l bl-l br-l bb-l b--theme-grey-light'
											}
											items={subCategories}
											itemTransform={(categoryProps) => (
												<CategoryTextTile
													{...categoryProps}
													key={categoryProps.id}
													linkColor="primary"
													tileClass={isNewLookAndFeel ? 'db ph2 pv3 w-auto' : 'ml3-ns'}
													className={isNewLookAndFeel ? 'bb b--theme-grey-light' : 'pa2 pl3-ns bl br bb'}
												/>
											)}
										/>
									)}
									{bottomSecondaryPromo && <PromoBanner {...bottomSecondaryPromo} />}
								</div>
								<div className="w-100 w-75-l h-100 relative">
									<div className="ml3-l">
										{topPromo && <PromoBanner {...topPromo} />}
										{isPlpVariationControl && (
											<GroupContent contentId={categoryContent?.id || ''} groups={filteredGroupContent} />
										)}
										{plpNavVariationsFlag !== PlpNavVariations.control && (
											<div className="dn db-l">
												<Carousel rootMargin="0% 10px">
													<FacetFilterListV2 />
												</Carousel>
											</div>
										)}
									</div>
									<div className={isNewLookAndFeel ? 'relative' : ''}>
										{products.length > 0 && (
											<LoadableSearchResults viewType={productViewPreference} dropType={dropType} />
										)}
										{count === 0 && selectedFacetGroups.length > 0 && <LoadableNoFilteredSearchResults />}
										{count === 0 && selectedFacetGroups.length === 0 && <LoadableNoResults type="category" />}
										<div ref={footerRef} className="ml3-l">
											{(footerInView || isChromatic()) && <LoadableSearchFooter hidePageSize={hidePageSize} />}
										</div>
									</div>
									{categoryContent?.categories.map((shopSection, index) => {
										return (
											<div key={index} className="mv6">
												<h3>Shop by Related Categories</h3>
												<CategoryBrowseList
													className="flex-row-ns flex-wrap items-center justify-start ph0 w-auto w-100-ns"
													items={shopSection.items?.filter(isCategoryCard) || null}
													itemTransform={(categoryProps, itemIndex, info) => (
														<CategoryImageTile
															{...categoryProps}
															className="ph2 mb3"
															key={`${categoryProps.id}|${categoryProps.name}`}
															tileClass={`${!info.isLast ? 'bb' : ''}`}
														/>
													)}
												/>
											</div>
										);
									})}
									<div ref={categoryContentRef} className="ml3-l">
										{categoryContent && categoryContentInView && (
											<CategoryContent categoryName={name} content={categoryContent} />
										)}
									</div>
								</div>
							</div>
							{isFacetDrawerOpen && (
								<FacetDrawer onFacetDrawerClose={hideFacetDrawer} searchHeaderV2FlagStatus={plpNavVariationsFlag} />
							)}
						</>
					) : (
						<PageLoading />
					)}
					<div id={`dy-recs-${categoryType}-page-1`} data-testid="dy-recs-placement-1" />
					{showAsEmployee && searchEngine && <CornerRibbon text={`${searchEngine} Search`} />}
				</>
			</PageContainer>
		</>
	);
};
