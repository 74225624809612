import React, { FunctionComponent } from 'react';
import { ArticleContentFieldsFragment } from '../../../../../__generated__/graphql-client-types';
import { RelatedArticle } from '../../../../../components/construct-components/related-article/related-article.component';

type RelatedArticlesProps = {
	categoryName: string;
	articles: ArticleContentFieldsFragment[];
};

export const RelatedArticles: FunctionComponent<RelatedArticlesProps> = ({ categoryName, articles }) => {
	return articles.length ? (
		<>
			<h3>
				{categoryName} <span className="i nb">Related Articles</span>
			</h3>
			<div className="flex flex-wrap justify-start w-100 items-stretch na2">
				{articles.map((article) => (
					<RelatedArticle key={article.id} {...article} />
				))}
			</div>
		</>
	) : null;
};
