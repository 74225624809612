import React, { FunctionComponent, HTMLAttributes } from 'react';
import {
	CategoryPageFieldsFragment,
	CategoryDropPageFieldsFragment,
	CommonPromoFieldsFragment
} from '../../../../../__generated__/graphql-client-types';
import { ContentContainer } from '../../../../../components/content-container/content-container.component';
import { PromoBanner } from '../../../../../components/promo-banner/promo-banner.component';
import { General } from '../../../../../types/content.types';
import { GeneralContent } from '../general-content/general-content.component';
import { PopularBrands } from '../popular-brands/popular-brands.component';
import { RelatedArticles } from '../related-articles/related-articles.component';
import { RelatedVideos } from '../related-videos/related-videos.component';

export type CategoryPageContentProps = { general: General[] } & (CategoryPageFieldsFragment | CategoryDropPageFieldsFragment);

export type CategoryContentProps = {
	categoryName: string;
	content: CategoryPageContentProps | null;
	promo?: CommonPromoFieldsFragment;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

export const CategoryContent: FunctionComponent<CategoryContentProps> = ({ className = '', categoryName, content, promo }) => {
	if (!content) {
		return null;
	}

	const popularBrands = content.brands.filter((brand) => brand.type === 'CATEGORY');
	const relatedArticles = content.articles.filter((article) => article.type === 'ARTICLE');
	const relatedVideos = content.videos.filter((video) => video.__typename === 'Video');

	return (
		<section className={className} data-testid="category-content">
			<ContentContainer contentId={content.id}>
				<PopularBrands categoryName={categoryName} brands={popularBrands} />
				<RelatedArticles categoryName={categoryName} articles={relatedArticles} />
				<RelatedVideos categoryName={categoryName} videos={relatedVideos} />
				<GeneralContent generalContentData={content.general} />
			</ContentContainer>
			{promo && (
				<div className={'mb4'}>
					<PromoBanner {...promo} />
				</div>
			)}
		</section>
	);
};
