import { ProductVariantFieldsFragment } from '../../__generated__/graphql-client-types';
import { SITE_ID } from '../../constants/internal';
import { PdpProductFamily } from '../../types/product.types';
import { doesWindowExist } from '../general-helper/general-helper';

/*
 * Populate product data on our internal datalayer, which
 * is intended for use by internal tools (such as our easy-links Chrome extension)
 */
export const populateProductData = (product: PdpProductFamily | undefined, variant?: ProductVariantFieldsFragment) => {
	if (doesWindowExist() && product) {
		window.internalDataLayer = {
			selectedFinish: {
				id: variant?.id,
				name: variant?.title
			},
			manufacturer: product.brandInfo.name,
			productId: product.productId,
			productFamilyId: product.id
		};
	}
};

/*
 * Populate category data for internal tools (such as easy-links)
 */
export const populateCategoryData = (categoryId: number) => {
	if (doesWindowExist()) {
		window.internalDataLayer = {
			categoryId,
			siteId: SITE_ID
		};
	}
};
