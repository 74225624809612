import React, { FunctionComponent, useState, useEffect } from 'react';
import { GroupContentFieldsFragment } from '../../__generated__/graphql-client-types';
import { flattenSelectedFacets, filterItemsByActiveFacets } from '../../helpers/category-helper/category.helper';
import { useSearchResults } from '../../hooks/apollo/search/search.hooks';
import { GroupContentItem } from '../../types/content.types';
import { Carousel } from '../carousel/carousel.component';
import { PromotedFilterPill } from '../search-components/new-facet-filter-list/promoted-filter-pill/promoted-filter-pill.component';

export type PromotedFilterListV2Props = {
	groups: GroupContentFieldsFragment[];
};
export const PromotedFilterListV2: FunctionComponent<PromotedFilterListV2Props> = ({ groups }) => {
	const { results } = useSearchResults();
	const contentItems: GroupContentItem[] = groups.flatMap((group) => group.items);
	const [activeFacetValues, setActiveFacetValues] = useState<string[]>([]);
	const [filteredItems, setFilteredItems] = useState(contentItems);
	const [shouldUpdateFilteredItems, setShouldUpdateFilteredItems] = useState(false);

	useEffect(() => {
		const selectedFacets = results?.selectedFacetGroups.flatMap((group) => group.facets);
		if (selectedFacets && selectedFacets.length !== activeFacetValues.length && results) {
			const newFilterValues = flattenSelectedFacets(results.selectedFacetGroups);
			setActiveFacetValues([...newFilterValues]);
			setShouldUpdateFilteredItems(true);
		}
	}, [results]);

	useEffect(() => {
		if (shouldUpdateFilteredItems) {
			const updatedItemsList = filterItemsByActiveFacets(contentItems, activeFacetValues);
			setFilteredItems([...updatedItemsList]);
			setShouldUpdateFilteredItems(false);
		}
	}, [activeFacetValues]);

	useEffect(() => {
		if (contentItems.length > 0) {
			setFilteredItems(contentItems);
		}
	}, [contentItems.length]);

	return filteredItems.length ? (
		<div data-testid="promoted-filter-list-v2">
			<Carousel rootMargin="0% 10px">
				<div className="flex flex-nowrap gc3" data-testid="promoted-filter-list-gap-container">
					{filteredItems.map((item, index) => (
						<PromotedFilterPill key={`${item.url}-${index}`} item={item} />
					))}
				</div>
			</Carousel>
		</div>
	) : null;
};
