import React, { FunctionComponent } from 'react';
import { BrandContentFieldsFragment } from '../../../../../__generated__/graphql-client-types';
import { CloudinaryOptions } from '../../../../../helpers/cloudinary-helper/cloudinary-helper';
import { CategoryImageTile } from '../category-tile/category-tile.component';

export type PopularBrandsProps = {
	brands: BrandContentFieldsFragment[];
	categoryName: string;
};

export const PopularBrands: FunctionComponent<PopularBrandsProps> = ({ brands, categoryName }) => {
	const cloudinaryOptions: CloudinaryOptions = {
		width: 127,
		height: 0
	};

	return brands.length ? (
		<>
			<h3>
				Popular <span className="i fw4">{categoryName} Brands</span>
			</h3>
			<div className="flex flex-wrap flex-nowrap-l flex-row-s b--theme-grey-light ba-l items-center justify-center">
				{brands.map((brand, index) => {
					return (
						<CategoryImageTile
							key={index}
							{...brand}
							className="w-auto pa1"
							tileClass="w-auto"
							showBorder={false}
							showName={false}
							showArrow={false}
							cloudinaryOptions={cloudinaryOptions}
						/>
					);
				})}
			</div>
		</>
	) : null;
};
