import { CommonPromoFieldsFragment, GroupContentFieldsFragment } from '../../__generated__/graphql-client-types';
import { ContentRichText, General, GeneralGroupContent, GroupContentItem } from '../../types/content.types';
import { Category, SelectedFacetGroup } from '../../types/search.types';

export const isBrandCategory = (category: Category): boolean => {
	return Boolean(category && category.breadcrumbs && category.breadcrumbs.length > 0 && category.breadcrumbs[0].name === 'Brands');
};

export const extractCategoryBrandName = (category: Category): string | null => {
	const brandName = isBrandCategory(category) && category.breadcrumbs && category.breadcrumbs.length > 1 && category.breadcrumbs[1].name;
	return brandName || null;
};

type PromoBannerPriorityHash = {
	topPrimary?: CommonPromoFieldsFragment;
	topSecondary?: CommonPromoFieldsFragment;
	bottomPrimary?: CommonPromoFieldsFragment;
	bottomSecondary?: CommonPromoFieldsFragment;
	topSecondaryCalc?: CommonPromoFieldsFragment;
};

const constructPriorityMap = {
	TOP: 'topPrimary',
	TOP_SECONDARY: 'topSecondary',
	BOTTOM: 'bottomPrimary',
	BOTTOM_SECONDARY: 'bottomSecondary',
	TOP_SECONDARY_CALC: 'topSecondaryCalc'
};

/**
 * Takes an array of category promos and returns them as an object keyed by
 * priority (saves us iterating the same array 4 times or mutating it 3 times)
 */
export const mapPromoBannersByPriority = (sharedCategoryPromos: CommonPromoFieldsFragment[]): PromoBannerPriorityHash => {
	const promos: { [key: string]: CommonPromoFieldsFragment } = {};
	for (const promo of sharedCategoryPromos) {
		promos[constructPriorityMap[promo.priority]] = promo;
	}
	return promos;
};

export const isContentRichText = (item: General): item is ContentRichText => {
	return item.__typename === 'ContentRichText';
};

export const isGeneralGroupContent = (item: General): item is GeneralGroupContent => {
	return item.__typename === 'GeneralGroupContent';
};

export const verifyGroupsHaveContent = (groups: GroupContentFieldsFragment[]) => {
	return groups.length && groups.some(({ title, items }) => title || items.length);
};

export const flattenSelectedFacets = (groups: SelectedFacetGroup[]): string[] => {
	return groups.flatMap((group) => group.facets).flatMap((facet) => facet.value);
};

export const filterItemsByActiveFacets = (items: GroupContentItem[], activeFacetValues: string[]): GroupContentItem[] => {
	return items.filter((item) => {
		const itemFacetsValues = item.facets.flatMap((facet) => facet.value);
		if (itemFacetsValues.some((value) => activeFacetValues.includes(value))) {
			return null;
		}
		return item;
	});
};
