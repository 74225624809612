import React, { FunctionComponent } from 'react';
import { VideoFieldsFragment } from '../../../../../__generated__/graphql-client-types';
import { RelatedVideo } from '../../../../../components/construct-components/related-video/related-video.component';

export type RelatedVideosProps = {
	categoryName: string;
	videos: VideoFieldsFragment[];
};

export const RelatedVideos: FunctionComponent<RelatedVideosProps> = ({ categoryName, videos }) => {
	return videos.length ? (
		<>
			<h3>
				{categoryName} <span className="i normal">Related Videos</span>
			</h3>
			<div className="flex flex-wrap justify-start w-100 items-stretch na2">
				{videos.map((video) => (
					<RelatedVideo key={video.id} {...video} />
				))}
			</div>
		</>
	) : null;
};
